import React, { Component } from "react";
import links from "../links.json";

class LinksBar extends Component {
  openWorkday = () => {
    console.log("Open Window", links.workday);
    window.open(links.workday);
  };
  openBravo = () => {
    console.log("Open Window", links.bravo);
    window.open(links.bravo);
  };
  openIDrive = () => {
    console.log("Open Window", links.idrive);
    window.open(links.idrive);
  };
  openAWS = () => {
    console.log("Open Window", links.aws);
    window.open(links.aws);
  };
  openInside = () => {
    console.log("Open Window", links.inside);
    window.open(links.inside);
  };
  openSpeakup = () => {
    console.log("Open Window", links.speakup);
    window.open(links.speakup);
  };
  openFutureOfWork = () => {
    console.log("Open Window", links.futureOfWork);
    window.open(links.futureOfWork);
  };
  openDevelopmentCalendar = () => {
    console.log("Open Window", links.development_calendar);
    window.open(links.development_calendar);
  };
  render() {
    return (
      <div
        className="linksbar"
        // style={{ textAlign: "center" }}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="linksmargin">
                <div
                  tabIndex="0"
                  className="linkButton workdayLinkImage"
                  title={"Open Workday - " + links.workday_text}
                  onClick={this.openWorkday}
                >
                  <div className="workdayOverlay">{links.workday_text}</div>
                </div>
                <div
                  tabIndex="0"
                  className="linkButton bravoLinkImage"
                  title={"Open Bravo! - " + links.bravo_text}
                  onClick={this.openBravo}
                >
                  <div className="bravoOverlay">{links.bravo_text}</div>
                </div>
                <div
                  tabIndex="0"
                  className="linkButton fowLinkImage"
                  title={"Open Future of Work - " + links.futureOfWork_text}
                  onClick={this.openFutureOfWork}
                >
                  <div className="fowOverlay">{links.futureOfWork_text}</div>
                </div>
                <div
                  tabIndex="0"
                  className="linkButton idriveLinkImage"
                  title={"Open Workday Learning - " + links.idrive_text}
                  onClick={this.openIDrive}
                >
                  <div className="idriveOverlay">{links.idrive_text}</div>
                </div>
                <div
                  tabIndex="0"
                  className="linkButton awsLinkImage"
                  title={
                    "Open Associate Well Being & Safety - " + links.aws_text
                  }
                  onClick={this.openAWS}
                >
                  <div className="awsOverlay">{links.aws_text}</div>
                </div>
                
                <div
                  tabIndex="0"
                  className="linkButton devCalendarLinkImage"
                  title={"Open Development Calendar - " + links.development_calendar}
                  onClick={this.openDevelopmentCalendar}
                >
                  <div className="devCalendarOverlay">{links.development_calendar_text}</div>
                </div>
                <div
                  tabIndex="0"
                  className="linkButton anytimeFeedbackLinkImage"
                  title={"Open  - Be Heard! - " + links.speakup_text}
                  onClick={this.openSpeakup}
                >
                  <div className="anytimeFeedbackOverlay">{links.speakup_text}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LinksBar;
